module.exports = {
  key: "finance",
  finance: {
    router_title: {
      deposit: "预缴费用",
      settlement: "结算",
      teacherFee: "Fee",
    },
  },
  attribute: {
    deposits: "预缴费用",
    deposit: "预缴费用",
    settlement: "结算",
    settlements: "结算",
    teacherFee: "教师费",
    amount: "金额",
    createMoment: "创建",
    submitMoment: "提交",
    chargeAt: "缴费时间",
    addDeposit: "增加预缴费用",
    addSettlement: "添加结算",
    editSettlement: "编辑结算",
    editDeposit: "编辑预缴费用",
    classes: "课程",
    student: "学生",
    sum: "总金额",
    Students: "学生",
    Teachers: "老师",
    wallet: "账户",
    addSalary: "计算工资",
    fromTime: "从",
    toTime: "到",
    balances: "结余",
    remaningHour: "剩余课时",
    totalUsed: "总用时",
    totalPay: "总支付",
    salarySum: "工资总和",
    sumOfTeacherSettlements: "教师安置总数",
    sumOfAllSettlements: "所有结算金额",
    settlementsOfClass: "课程结算",
    teacherTotalGained: "教师收入总和",
    class_empty: "没有课!",
    remaningChargeInHour: "剩余的费用",
    confirmMessage: "确认消息",
    costPerHour: "每小时成本",
    shouldCharge: "应该收费",
    shouldPay: "应支付",
    totalPayHour: "总支付小时数",
    teachHour: "课时",
    monthlyTeaching: "每月教学",
    totalMonthlyTaught: "每月授课总数",
    teachFee: "课时费",
    lowAmount: "这个班的学生预存费用较少",
    shouldChargeInHour: "应该缴费",
    find: "找到",
    fromTo: "来自:",
    settlementType: "结算类型",
    settlementDescription: "描述",
    classroomTotalCost: "课堂总费用",
    totalGained: "总获得",
    totalTeachHour: "总教学时长",
    studentsTotalPayForToTime: "学生总支付",
    classroomsDetail: "课堂细节",
    studentsTotalPayForClassroom: "学生总薪酬",
    report: "报告",
    totalPayedAmount: "已付总金额",
    totalShouldPayToTeacher: "总应支付给老师",
    shouldPayToTeacher: "应该付钱给老师",
    salaryHistory: "历史与总结算",
    classroomStudents: "班级学生",
    payedAmount: "支付金额",
    incomeInHour: "收入/小时",
    charge: "收费/小时",
    exportAll: "导出全部",
    teachingSalary: "教学工资",
    TeachingSalary: "教学工资",
    otherPayments: "其他付款",
    OtherPayments: "其他付款",
    academicConsultantPublicKey: "学术顾问",
    ACProfit: "学术顾问利润",
    profit: "合约价值",
    show: "展示",
    depositType: "存款类型",
    selectClass: "请选择班级",
    selectContract: "请选择合同",
    discountValue: "折扣值",
    id: "ID",
    continue: "继续",
    continue_sure: "您确定要继续吗？",
    transfer: "转移",
    transferBalance: "转账余额",
    addTransfer: "创建传输",
    sourceDepositId: "源存款",
    destinationDepositId: "目的地存款",
    transfers: "接送服务",
    sourceClassroomName: "来源教室名称",
    destinationClassroomName: "目的地教室名称",
    editTransfer: "编辑转接",
    contractFile: "合同文件",
    confirmedBy: "经证实",
    mainConfirmedBy: "财务确认",
    nobody: "没有人",
    ACConfirmation: "学术顾问确认",
    FAConfirmation: "财务管理确认",
    FAMessage: "财务管理留言",
    ACMessage: "学术顾问留言",
    teacherLessonType: "课程类型",
    changeConsultant: "变革顾问",
    academicConsultants: "顾问",
    teacherName: "教师姓名",
    destinationClassroom: "目的地教室",
    sourceClassroom: "源课堂",
    isConfirmed: "确认状态",
    confirmed: "确认的",
    notConfirmed: "还没有确定",
    debtorStudents: "学生欠费明细",
    studentFullName: "学生姓名",
    reservedSessionCount: "预留场次",
    teacherFullName: "老师全名",
    addGroupSalary: "添加团体工资",
    totalTeachHours: "总教学时数",
    totalMonthlyTeachingHour: "每月教学总量",
    monthly: "每月",
    fee: "费",
    numberOfStudentsInAClass: "班级学生人数",
    sideCost: "附带成本",
    ClassroomSideCosts: "教室边构造",
    Contract: "合同",
    shouldPayForSideCosts: "应该支付附带费用",
    sideCosts: "附带成本",
    totalShouldPayForSideCosts: "总计应支付附带成本",
    totalPaidForSideCosts: "支付的附带成本总额",
    sideCostsBalance: "边际成本平衡",
    sourceClassroomTeacherFullName: "来源课堂老师",
    sourceClassroomCharge: "来源班级课时数/小时",
    sourceClassroomIncomeInHour: "来源班级单价/元",
    destinationClassroomTeacherFullName: "目的地任课教师",
    destinationClassroomCharge: "目标班级课时数/小时",
    destinationClassroomIncomeInHour: "目标班级单价/元",
    confirmationDate: "确认日期",

  },
};
